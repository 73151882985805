
























































































































.col-study {
  display: block;
}
.element-wrapper {
  position: relative;
  .pdf-download {
    position: absolute;
    top: 20px;
    left: 20px;
    text-align: center;
  }
}
.section__front-video {
  max-width: 100%;
}
.study-item__pdf > div {
  width: 100%;
  text-align: center;
  min-height: 200px;
  position: relative;
  .spinner-border {
    display: block!important;
    top: 50%!important;
    position: absolute!important;
  }
}
